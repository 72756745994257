<template>
  <div class="estate-highlight">
    <div class="item y3" v-for="(item, index) in list" :key="index">
      <p class="title">{{ item.title }}</p>
      <p class="desc color9">{{ item.description }}</p>
      <div class="icon-group">
        <svg-icon icon-class="edit" @click="showEditDialoag(item)"></svg-icon>
        <svg-icon
          icon-class="delete"
          @click="deleteEstateSellPoint(item.id)"
        ></svg-icon>
      </div>
    </div>
    <div class="item center blue" @click="toggleDialog = true">
      <i class="el-icon-plus"></i>
      <span>添加一条亮点</span>
    </div>
    <el-dialog
      :title="formData.id ? '编辑亮点' : '创建亮点'"
      class="f-dialog"
      :visible.sync="toggleDialog"
      width="490px"
      @close="resetForm()"
    >
      <el-form class="f-form" :model="formData" label-width="100px" ref="form">
        <el-form-item
          prop="title"
          label="亮点标题："
          :rules="[rules.required()]"
        >
          <el-input
            v-model="formData.title"
            placeholder="4-6个汉字为宜，如“6万平米园林”"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="mb0"
          prop="description"
          label="亮点阐述："
          :rules="[rules.required()]"
        >
          <el-input
            v-model="formData.description"
            type="textarea"
            rows="3"
            placeholder="20个汉字以内为宜"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="toggleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEstateSellPointList,
  createEstateSellPoint,
  editEstateSellPoint,
  deleteEstateSellPoint
} from "@/api/estate";
import rules from "@/utils/rules";

const formData = {
  id: null,
  title: "",
  description: ""
};

export default {
  data() {
    return {
      rules,
      list: [],
      toggleDialog: false,
      formData: JSON.parse(JSON.stringify(formData))
    };
  },
  created() {
    this.getEstateSellPointList();
  },
  methods: {
    resetForm() {
      //重置表单
      this.formData = JSON.parse(JSON.stringify(formData));
      this.$refs.form.resetFields();
    },
    showEditDialoag(item) {
      this.formData = this.$deepClone(item);
      this.toggleDialog = true;
    },
    async deleteEstateSellPoint(id) {
      //删除亮点
      this.$confirm("删除后无法恢复，确定删除该亮点吗？", "删除亮点", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteEstateSellPoint({ id });
          if (res) {
            this.$showSuccess("删除成功");
            this.getEstateSellPointList();
          }
        })
        .catch(() => {});
    },
    async getEstateSellPointList() {
      //查询楼盘亮点列表
      const res = await getEstateSellPointList();
      if (res) {
        this.list = res;
      }
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const fn = this.formData.id
          ? editEstateSellPoint
          : createEstateSellPoint;
        let res = await fn(this.formData);
        if (res) {
          this.toggleDialog = false;
          this.resetForm();
          this.$showSuccess("操作成功");
          this.getEstateSellPointList();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.estate-highlight {
  display: flex;
  flex-wrap: wrap;
}
.item {
  width: 325px;
  min-width: 325px;
  height: 180px;
  padding: 15px 20px;
  margin: 0 30px 30px 0;
  border: 1px solid $bgColor;
  border-radius: 5px;
  .icon-group {
    font-size: 18px;
    color: $blue;
    text-align: right;
    svg {
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  .desc {
    height: 80px;
    padding: 0 20px;
    margin: 0 -20px;
    overflow-y: auto;
  }
  &:hover {
    background-color: $bgColor;
  }
  &.center {
    cursor: pointer;
    .el-icon-plus {
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .title {
    font-weight: bold;
  }
}
</style>
