<template>
  <div class="estate-base-info-tab y">
    <el-tabs class="f-tabs" v-model="activeName">
      <el-tab-pane
        v-for="(item, index) in tabs"
        :label="item"
        :name="`${index}`"
        :key="index"
      ></el-tab-pane>
    </el-tabs>
    <div class="wrap">
      <div class="f-container">
        <base-info v-show="activeName == 0"></base-info>
        <highlight v-show="activeName == 1"></highlight>
        <price-info v-show="activeName == 2"></price-info>
        <opening v-show="activeName == 3"></opening>
      </div>
    </div>
  </div>
</template>

<script>
import baseInfo from "./components/baseInfo";
import highlight from "./components/highlight";
import priceInfo from "./components/priceInfo";
import opening from "./components/opening";
export default {
  components: {
    baseInfo,
    highlight,
    priceInfo,
    opening
  },
  data() {
    return {
      tabs: ["基本信息", "楼盘亮点", "价格信息", "开盘记录"],
      activeName: "0"
    };
  },
  created() {}
};
</script>
<style lang="scss">
.estate-base-info-tab {
  height: 100%;
  .f-tabs {
    // width: calc(100% + 40px);
    width: 100%;
    margin: -22px 0 0 -20px;
    position: absolute;
    z-index: 2;
    .el-tabs__header {
      background: #fff;
      .el-tabs__nav {
        margin-left: 20px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.estate-base-info-tab {
  .wrap {
    flex: 1;
    margin-top: 60px;
    overflow-y: auto;
  }
}
</style>
