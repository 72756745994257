var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estate-opening-info"
  }, [_c('div', {
    staticClass: "head x3"
  }, [_vm._m(0), _c('div', [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.toggleDialog = true;
      }
    }
  }, [_vm._v("创建开盘")])], 1)]), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "开盘日期"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [scope.row.openStatus == 2 ? _c('span', {
          staticClass: "green"
        }, [_vm._v("已拿证")]) : _c('span', {
          staticClass: "blue"
        }, [_vm._v("即将开盘")]), _c('span', [_vm._v("，" + _vm._s(scope.row.openStatus == 2 ? scope.row.openDate : scope.row.openDateDesc))])]), _c('p', {
          staticClass: "color9 mt10"
        }, [_c('span', [_vm._v(_vm._s(scope.row.propertyTypeStr))]), scope.row.decoration ? _c('span', [_vm._v(" | " + _vm._s(scope.row.decoration))]) : _vm._e(), scope.row.areaTo ? _c('span', [_vm._v(" | " + _vm._s(scope.row.areaFrom) + "-" + _vm._s(scope.row.areaTo) + "㎡")]) : _vm._e()])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "价格信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._l(_vm.handleListPriceInfo(scope.row.priceItems), function (item, index) {
          return _c('p', {
            key: index
          }, [_vm._v(" " + _vm._s(item.label) + "：" + _vm._s(item.desc) + " ")]);
        }), !scope.row.priceItems || !scope.row.priceItems.length ? _c('p', [_vm._v(" 价格待定 ")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "预售许可/预售范围"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.preSalePermit || scope.row.preSaleRange ? _c('div', [_c('p', [_vm._v(" " + _vm._s(scope.row.preSalePermit ? "预/现售证号:" + scope.row.preSalePermit : "-") + " ")]), _c('p', [_vm._v(_vm._s(scope.row.preSaleRange || "-"))])]) : _c('p', [_vm._v("-")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showEdit(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.delRecord(scope.row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.toggleDialog,
      "title": "".concat(_vm.formData.id ? '编辑' : '创建', "\u5F00\u76D8\u4FE1\u606F"),
      "width": "650px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialog = $event;
      },
      "close": function close($event) {
        return _vm.resetForm();
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "f-form required-type2",
    attrs: {
      "model": _vm.formData,
      "label-position": "top"
    }
  }, [_c('div', {
    staticClass: "x3"
  }, [_c('el-form-item', {
    attrs: {
      "label": "开盘状态",
      "prop": "openStatus",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.openStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "openStatus", $$v);
      },
      expression: "formData.openStatus"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("即将开盘")]), _c('el-radio-button', {
    attrs: {
      "label": 2
    }
  }, [_vm._v("已拿证")])], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "物业类型",
      "prop": "propertyType",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.propertyType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyType", $$v);
      },
      expression: "formData.propertyType"
    }
  }, _vm._l(_vm.propertytypes, function (item, index) {
    return _c('el-radio-button', {
      key: index,
      attrs: {
        "label": item.code
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1), _vm.formData.openStatus == 1 ? _c('div', {
    staticClass: "x3"
  }, [_c('el-form-item', {
    attrs: {
      "label": "预计开盘日期(用于前台显示)",
      "prop": "openDateDesc",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    staticClass: "w250",
    attrs: {
      "placeholder": "请填写预计开盘日期"
    },
    model: {
      value: _vm.formData.openDateDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "openDateDesc", $$v);
      },
      expression: "formData.openDateDesc"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "预计开盘日期（仅用于排序、筛选）",
      "prop": "openDate",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-date-picker', {
    staticClass: "w250",
    attrs: {
      "value-format": "yyyy-MM-dd",
      "type": "date",
      "placeholder": "选择日期"
    },
    model: {
      value: _vm.formData.openDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "openDate", $$v);
      },
      expression: "formData.openDate"
    }
  })], 1)], 1) : _vm._e(), _vm.formData.openStatus == 2 ? _c('div', {
    staticClass: "x3"
  }, [_c('el-form-item', {
    attrs: {
      "label": "开盘日期",
      "prop": "openDate",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-date-picker', {
    staticClass: "w250",
    attrs: {
      "value-format": "yyyy-MM-dd",
      "type": "date",
      "placeholder": "选择日期"
    },
    model: {
      value: _vm.formData.openDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "openDate", $$v);
      },
      expression: "formData.openDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "主推面积",
      "prop": "areaFrom",
      "rules": [_vm.rules.int]
    }
  }, [_c('div', {
    staticClass: "area-limit"
  }, [_c('el-input', {
    staticClass: "w150",
    attrs: {
      "placeholder": "最小面积"
    },
    model: {
      value: _vm.formData.areaFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "areaFrom", _vm._n($$v));
      },
      expression: "formData.areaFrom"
    }
  }), _c('span', {
    staticClass: "pw10"
  }, [_vm._v("-")]), _c('el-form-item', {
    attrs: {
      "prop": "areaTo",
      "rules": [_vm.rules.int]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "最大面积"
    },
    model: {
      value: _vm.formData.areaTo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "areaTo", _vm._n($$v));
      },
      expression: "formData.areaTo"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("平米")])])], 1)], 1)])], 1) : _vm._e(), _vm.formData.openStatus == 2 ? _c('div', {
    staticClass: "x3"
  }, [_c('el-form-item', {
    attrs: {
      "label": "装修情况"
    }
  }, [_c('el-input', {
    staticClass: "w250",
    model: {
      value: _vm.formData.decoration,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "decoration", $$v);
      },
      expression: "formData.decoration"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "预售许可证"
    }
  }, [_c('el-input', {
    staticClass: "w250",
    model: {
      value: _vm.formData.preSalePermit,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "preSalePermit", $$v);
      },
      expression: "formData.preSalePermit"
    }
  })], 1)], 1) : _vm._e(), _vm.formData.openStatus == 2 ? _c('el-form-item', {
    attrs: {
      "label": "预售范围"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.preSaleRange,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "preSaleRange", $$v);
      },
      expression: "formData.preSaleRange"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "价格信息"
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_vm._l(_vm.formData.priceItems, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "price-from-form"
    }, [_c('div', {
      staticClass: "price-item"
    }, [_c('el-select', {
      attrs: {
        "placeholder": ""
      },
      model: {
        value: item.priceType,
        callback: function callback($$v) {
          _vm.$set(item, "priceType", $$v);
        },
        expression: "item.priceType"
      }
    }, [_c('el-option', {
      attrs: {
        "value": 1,
        "label": "均价"
      }
    }), _c('el-option', {
      attrs: {
        "value": 2,
        "label": "总价"
      }
    })], 1), _c('el-form-item', {
      attrs: {
        "prop": "priceItems." + index + ".".concat(item.isInterval ? 'priceFrom' : 'priceDesc'),
        "rules": [item.isInterval ? _vm.rules.number : {}]
      }
    }, [!item.isInterval ? _c('el-input', {
      staticClass: "w130",
      attrs: {
        "maxlength": "5",
        "placeholder": "价格描述，选填"
      },
      model: {
        value: item.priceDesc,
        callback: function callback($$v) {
          _vm.$set(item, "priceDesc", $$v);
        },
        expression: "item.priceDesc"
      }
    }) : _c('el-input', {
      staticClass: "w130",
      attrs: {
        "maxlength": "6",
        "placeholder": item.priceType == 1 ? '最低均价' : '最低总价'
      },
      model: {
        value: item.priceFrom,
        callback: function callback($$v) {
          _vm.$set(item, "priceFrom", $$v);
        },
        expression: "item.priceFrom"
      }
    })], 1), _c('span', {
      style: {
        opacity: item.isInterval ? 1 : 0
      }
    }, [_vm._v("-")]), _c('el-form-item', {
      attrs: {
        "prop": "priceItems." + index + ".priceTo",
        "rules": [_vm.rules.number]
      }
    }, [_c('el-input', {
      staticClass: "w172",
      attrs: {
        "placeholder": item.isInterval ? item.priceType == 1 ? '最高均价' : '最高总价' : '请输入'
      },
      model: {
        value: item.priceTo,
        callback: function callback($$v) {
          _vm.$set(item, "priceTo", $$v);
        },
        expression: "item.priceTo"
      }
    }, [_c('span', {
      attrs: {
        "slot": "append"
      },
      slot: "append"
    }, [_vm._v(" " + _vm._s(item.priceType == 1 ? "元/平米" : item.isInterval ? "万元/套" : "万元/套起") + " ")])])], 1), _c('el-checkbox', {
      model: {
        value: item.isInterval,
        callback: function callback($$v) {
          _vm.$set(item, "isInterval", $$v);
        },
        expression: "item.isInterval"
      }
    }, [_vm._v("价格区间")]), index > 0 ? _c('i', {
      staticClass: "el-icon-remove",
      on: {
        "click": function click($event) {
          return _vm.removePirceItem(index);
        }
      }
    }) : _vm._e()], 1)]);
  }), _vm.formData.priceItems.length < 3 ? _c('el-button', {
    staticClass: "add-price-btn",
    on: {
      "click": _vm.addPirceItem
    }
  }, [_vm._v("添加更多")]) : _vm._e(), _c('p', {
    staticClass: "f-form-tips"
  }, [_vm._v(" 最多可添加3组价格信息，价格描述不超过5个汉字 ")])], 2)])], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.toggleDialog = false;
      }
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h3', [_vm._v("开盘记录")]), _c('p', [_vm._v(" 即将开盘，指未来三个月内预计要开盘，只需填写大致开盘月份即可，价格信息不会同步更新； ")]), _c('p', [_vm._v(" 已拿证，指已取得预售许可证，有明确的开盘时间、开盘价格等，楼盘价格信息会同步更新； ")])]);

}]

export { render, staticRenderFns }