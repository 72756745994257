<template>
  <div class="estate-opening-info">
    <div class="head x3">
      <div class="title">
        <h3>开盘记录</h3>
        <p>
          即将开盘，指未来三个月内预计要开盘，只需填写大致开盘月份即可，价格信息不会同步更新；
        </p>
        <p>
          已拿证，指已取得预售许可证，有明确的开盘时间、开盘价格等，楼盘价格信息会同步更新；
        </p>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="toggleDialog = true"
          >创建开盘</el-button
        >
      </div>
    </div>
    <el-table :data="list" class="mt20">
      <el-table-column label="开盘日期">
        <template slot-scope="scope">
          <p>
            <span v-if="scope.row.openStatus == 2" class="green">已拿证</span>
            <span v-else class="blue">即将开盘</span>
            <span
              >，{{
                scope.row.openStatus == 2
                  ? scope.row.openDate
                  : scope.row.openDateDesc
              }}</span
            >
          </p>
          <p class="color9 mt10">
            <span>{{ scope.row.propertyTypeStr }}</span>
            <span v-if="scope.row.decoration">
              | {{ scope.row.decoration }}</span
            >
            <span v-if="scope.row.areaTo">
              | {{ scope.row.areaFrom }}-{{ scope.row.areaTo }}㎡</span
            >
          </p>
        </template>
      </el-table-column>
      <el-table-column label="价格信息">
        <template slot-scope="scope">
          <p
            v-for="(item, index) in handleListPriceInfo(scope.row.priceItems)"
            :key="index"
          >
            {{ item.label }}：{{ item.desc }}
          </p>
          <p v-if="!scope.row.priceItems || !scope.row.priceItems.length">
            价格待定
          </p>
        </template>
      </el-table-column>
      <el-table-column label="预售许可/预售范围">
        <template slot-scope="scope">
          <div v-if="scope.row.preSalePermit || scope.row.preSaleRange">
            <p>
              {{
                scope.row.preSalePermit
                  ? "预/现售证号:" + scope.row.preSalePermit
                  : "-"
              }}
            </p>
            <p>{{ scope.row.preSaleRange || "-" }}</p>
          </div>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px">
        <template slot-scope="scope">
          <el-button type="text" @click="showEdit(scope.row)">编辑</el-button>
          <el-button type="text" class="red" @click="delRecord(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="toggleDialog"
      class="f-dialog"
      :title="`${formData.id ? '编辑' : '创建'}开盘信息`"
      width="650px"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        :model="formData"
        ref="form"
        label-position="top"
        class="f-form  required-type2"
      >
        <div class="x3">
          <el-form-item
            label="开盘状态"
            prop="openStatus"
            :rules="[rules.required()]"
          >
            <el-radio-group v-model="formData.openStatus">
              <el-radio-button :label="1">即将开盘</el-radio-button>
              <el-radio-button :label="2">已拿证</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="物业类型"
            prop="propertyType"
            :rules="[rules.required()]"
          >
            <el-radio-group v-model="formData.propertyType">
              <el-radio-button
                v-for="(item, index) in propertytypes"
                :key="index"
                :label="item.code"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="x3" v-if="formData.openStatus == 1">
          <el-form-item
            label="预计开盘日期(用于前台显示)"
            prop="openDateDesc"
            :rules="[rules.required()]"
          >
            <el-input
              class="w250"
              placeholder="请填写预计开盘日期"
              v-model="formData.openDateDesc"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="预计开盘日期（仅用于排序、筛选）"
            prop="openDate"
            :rules="[rules.required()]"
          >
            <el-date-picker
              class="w250"
              v-model="formData.openDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="x3" v-if="formData.openStatus == 2">
          <el-form-item
            label="开盘日期"
            prop="openDate"
            :rules="[rules.required()]"
          >
            <el-date-picker
              class="w250"
              v-model="formData.openDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="主推面积" prop="areaFrom" :rules="[rules.int]">
            <div class="area-limit">
              <el-input
                placeholder="最小面积"
                class="w150"
                v-model.number="formData.areaFrom"
              ></el-input>
              <span class="pw10">-</span>
              <el-form-item prop="areaTo" :rules="[rules.int]">
                <el-input
                  placeholder="最大面积"
                  v-model.number="formData.areaTo"
                >
                  <span slot="append">平米</span>
                </el-input>
              </el-form-item>
            </div>
          </el-form-item>
        </div>
        <div class="x3" v-if="formData.openStatus == 2">
          <el-form-item label="装修情况">
            <el-input class="w250" v-model="formData.decoration"></el-input>
          </el-form-item>
          <el-form-item label="预售许可证">
            <el-input class="w250" v-model="formData.preSalePermit"></el-input
          ></el-form-item>
        </div>
        <el-form-item label="预售范围" v-if="formData.openStatus == 2">
          <el-input v-model="formData.preSaleRange"></el-input>
        </el-form-item>
        <el-form-item label="价格信息">
          <div class="f-form-block">
            <div
              v-for="(item, index) in formData.priceItems"
              :key="index"
              class="price-from-form"
            >
              <div class="price-item">
                <el-select placeholder="" v-model="item.priceType">
                  <el-option :value="1" label="均价"></el-option>
                  <el-option :value="2" label="总价"></el-option>
                </el-select>
                <el-form-item
                  :prop="
                    `priceItems.` +
                      index +
                      `.${item.isInterval ? 'priceFrom' : 'priceDesc'}`
                  "
                  :rules="[item.isInterval ? rules.number : {}]"
                >
                  <el-input
                    v-if="!item.isInterval"
                    class="w130"
                    maxlength="5"
                    placeholder="价格描述，选填"
                    v-model="item.priceDesc"
                  ></el-input>
                  <el-input
                    v-else
                    class="w130"
                    maxlength="6"
                    :placeholder="item.priceType == 1 ? '最低均价' : '最低总价'"
                    v-model="item.priceFrom"
                  ></el-input>
                </el-form-item>
                <span :style="{ opacity: item.isInterval ? 1 : 0 }">-</span>
                <el-form-item
                  :prop="`priceItems.` + index + `.priceTo`"
                  :rules="[rules.number]"
                >
                  <el-input
                    class="w172"
                    :placeholder="
                      item.isInterval
                        ? item.priceType == 1
                          ? '最高均价'
                          : '最高总价'
                        : '请输入'
                    "
                    v-model="item.priceTo"
                  >
                    <span slot="append">
                      {{
                        item.priceType == 1
                          ? "元/平米"
                          : item.isInterval
                          ? "万元/套"
                          : "万元/套起"
                      }}
                    </span>
                  </el-input>
                </el-form-item>
                <el-checkbox v-model="item.isInterval">价格区间</el-checkbox>
                <i
                  class="el-icon-remove"
                  v-if="index > 0"
                  @click="removePirceItem(index)"
                ></i>
              </div>
            </div>
            <el-button
              class="add-price-btn"
              v-if="formData.priceItems.length < 3"
              @click="addPirceItem"
              >添加更多</el-button
            >
            <p class="f-form-tips">
              最多可添加3组价格信息，价格描述不超过5个汉字
            </p>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="toggleDialog = false">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOpenInfoList,
  getEstatePropertytypes,
  createOpenInfo,
  editOpenInfo,
  deleteOpenInfo
} from "@/api/estate";
import rules from "@/utils/rules";
import mixin from "../mixin";

const priceItem = {
  isInterval: false, //0.非区间 1.区间
  priceDesc: "", //价格描述
  priceFrom: "",
  priceId: null,
  priceTo: "",
  priceType: 1 //价格类型：1:单价(单位元) 2:总价(单位万元)
};

const formData = {
  id: null,
  //面积段
  areaFrom: "",
  areaTo: "",
  decoration: "", //装修情况
  openDate: "", //开盘日期
  openDateDesc: "", //开盘日期描述
  openStatus: 1, //开盘状态1--即将开盘，2--已拿证 ,
  preSalePermit: "", //预售许可证
  preSaleRange: "", //预售范围
  priceItems: [JSON.parse(JSON.stringify(priceItem))], //价格项 ,
  propertyType: "" //1--普通住宅，2--公寓，3--别墅，4--写字楼，5--商铺 ,
};
export default {
  mixins: [mixin],
  data() {
    return {
      rules,
      toggleDialog: false, //编辑对话框
      list: [{}],
      formData: JSON.parse(JSON.stringify(formData)),
      propertytypes: []
    };
  },
  created() {
    this.getOpenInfoList();
    this.getEstatePropertytypes();
  },
  methods: {
    delRecord(id) {
      //产出记录
      this.$confirm("删除后无法恢复，确定删除该记录吗？", "删除记录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteOpenInfo({ id });
          if (res) {
            this.$showSuccess("删除成功");
            this.getOpenInfoList();
          }
        })
        .catch(() => {});
    },
    showEdit(item) {
      //点击编辑
      this.formData = this.$deepClone(item);
      this.toggleDialog = true;
    },
    resetForm() {
      //重置表单
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields();
    },
    async getOpenInfoList() {
      // 查询开盘信息列表
      const res = await getOpenInfoList();
      if (res) {
        this.list = res;
      }
    },
    async getEstatePropertytypes() {
      // 查询楼盘物业类型
      const res = await getEstatePropertytypes();
      if (res) {
        this.propertytypes = res;
      }
    },
    addPirceItem() {
      //添加价格条目
      if (this.formData.priceItems.length < 3) {
        this.formData.priceItems.push(this.$deepClone(priceItem));
      }
    },
    removePirceItem(index) {
      //移除价格条目
      this.formData.priceItems.splice(index, 1);
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        let param = this.$deepClone(this.formData);
        let limitFormatError = false; //验证价格条目格式
        param.priceItems = param.priceItems.map(item => {
          if (!item.isInterval) {
            //如果选的不是价格区间，为priceFromc赋值
            item.priceFrom = item.priceTo;
            if (item.priceDesc && !item.priceTo) {
              //如果输入了描述，没有输入价格
              limitFormatError = 2;
            }
          } else {
            item.priceDesc = "";
            //如果起始价格大于结束价格
            if (Number(item.priceFrom) > Number(item.priceTo)) {
              limitFormatError = 1;
            }
            if (item.priceFrom && !item.priceTo) {
              //如果填了起始价格，未填写结束价格
              limitFormatError = 2;
            } else if (!item.priceFrom && item.priceTo) {
              //如果填了结束价格，未填写起始价格
              limitFormatError = 2;
            }
          }
          return item;
        });
        if (limitFormatError == 2) {
          this.$showError("价格信息未填写完整");
          return;
        }
        if (limitFormatError == 1) {
          this.$showError("最小金额不能大于最大金额");
          return;
        }
        if (
          param.areaFrom &&
          param.areaFrom &&
          Number(param.areaFrom) > Number(param.areaTo)
        ) {
          this.$showError("最小面积不能大于最大面积");
          return;
        }
        param.priceItems = param.priceItems.filter(item => item.priceTo);
        if (param.openStatus == 1) {
          //如果编辑的时候状态切换回“即将开盘” 清空“已拿证”才能填写的相关数据
          param.decoration = "";
          param.preSalePermit = "";
          param.preSaleRange = "";
          param.areaFrom = "";
          param.areaTo = "";
        }
        const fn = this.formData.id ? editOpenInfo : createOpenInfo;
        const res = await fn(param);
        if (res) {
          this.toggleDialog = false;
          this.resetForm();
          this.$showSuccess("操作成功");
          this.getOpenInfoList();
        }
      });
    }
  }
};
</script>
<style lang="scss">
.estate-opening-info {
  .x3 {
    .el-form-item {
      width: 250px;
    }
  }
  .area-limit {
    display: flex;
    .el-input__inner {
      width: 89px;
    }
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .f-form {
    padding: 0 20px;
  }
  .el-form {
    .w250 {
      width: 250px;
    }
    .price-item {
      display: flex;
      align-items: center;
      & > * {
        margin-left: 10px;
      }
      .el-input-group__append {
        width: 70px;
        padding: 0;
        text-align: center;
      }
      .el-select {
        min-width: 74px;
        max-width: 74px;
        margin-left: 10px;
      }
      .w130 {
        min-width: 130px;
        max-width: 130px;
      }
      .w172 {
        min-width: 172px;
        max-width: 172px;
      }
      .el-checkbox {
        .el-checkbox__inner {
          margin-left: 5px;
          transform: scale(1.5);
        }
      }
      .el-icon-remove {
        font-size: 24px;
        color: $red;
        cursor: pointer;
        &:active {
          opacity: 0.75;
        }
      }
    }
    .price-from-form {
      margin-bottom: 10px;
      & > .el-form-item__content {
        & > .el-form-item__error {
          left: 102px;
        }
      }
    }
    .add-price-btn {
      padding: 9.5px 20px;
      margin: 0 0 10px 10px;
      width: 100px;
      color: $blue;
      background: #eee;
    }
    .f-form-tips {
      margin-left: 10px;
    }
  }
}
</style>

<style scoped lang="scss">
.head {
  .title {
    h3 {
      font-size: 16px;
      line-break: 16px;
      padding-left: 20px;
      border-left: 3px solid $primary;
    }
    p {
      margin-top: 10px;
      padding-left: 20px;
      color: $color9;
    }
  }
}
</style>
