var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estate-base-info-tab y"
  }, [_c('el-tabs', {
    staticClass: "f-tabs",
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c('el-tab-pane', {
      key: index,
      attrs: {
        "label": item,
        "name": "".concat(index)
      }
    });
  }), 1), _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "f-container"
  }, [_c('base-info', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeName == 0,
      expression: "activeName == 0"
    }]
  }), _c('highlight', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeName == 1,
      expression: "activeName == 1"
    }]
  }), _c('price-info', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeName == 2,
      expression: "activeName == 2"
    }]
  }), _c('opening', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeName == 3,
      expression: "activeName == 3"
    }]
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }