const mixin = {
  methods: {
    handleListPriceInfo(list) {
      //处理价格信息数据 @list 价格列表
      if (!list) return;
      let res = this.$deepClone(list);
      res = res.map(item => {
        let unit = "元/平米";
        if (item.priceType == 1) {
          item.label = "参考单价";
        } else if (item.priceType == 2) {
          item.label = "参考总价";
          unit = "万元/套起";
          if (item.isInterval) {
            unit = "万元/套";
          }
        }
        if (item.priceDesc) {
          //有描述
          item.desc = `${item.priceDesc} ${item.priceTo}${unit}`;
        } else {
          //价格区间
          if (item.priceFrom == item.priceTo) {
            item.desc = `${item.priceTo}${unit}`;
          } else {
            item.desc = `${item.priceFrom}-${item.priceTo}${unit}`;
          }
        }
        return item;
      });

      //合并相同价格类型数据
      let result = res.reduce((groups, item) => {
        let groupFound = groups.find(
          arrItem => item.priceType === arrItem.priceType
        );
        if (groupFound) {
          groupFound.desc += `；${item.desc}`;
        } else {
          //不直接在原来的对象修改，新建对象
          let newGroup = {
            label: item.label,
            priceType: item.priceType,
            desc: item.desc
          };
          groups.push(newGroup);
        }
        return groups;
      }, []);
      return result;
    }
  }
};

export default mixin;
