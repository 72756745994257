var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estate-price-info"
  }, [_c('div', {
    staticClass: "head x3"
  }, [_vm._m(0), _c('div', [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.toggleDialog = true;
      }
    }
  }, [_vm._v("创建价格")])], 1)]), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "价格信息",
      "width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(_vm.handleListPriceInfo(scope.row.priceItems), function (item, index) {
          return _c('p', {
            key: index
          }, [_vm._v(" " + _vm._s(item.label) + "：" + _vm._s(item.desc) + " ")]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "物业类型",
      "prop": "propertyTypeName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "价格变动日期",
      "prop": "priceChangeDate"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "备注",
      "prop": "remark"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(_vm._s(scope.row.remark || "无"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showEdit(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.delRecord(scope.row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.toggleDialog,
      "title": "".concat(_vm.formData.id ? '编辑' : '创建', "\u4EF7\u683C\u4FE1\u606F"),
      "width": "650px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialog = $event;
      },
      "close": function close($event) {
        return _vm.resetForm();
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "f-form required-type2",
    attrs: {
      "model": _vm.formData,
      "label-position": "top"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "物业类型",
      "prop": "propertyType",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.propertyType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyType", $$v);
      },
      expression: "formData.propertyType"
    }
  }, _vm._l(_vm.propertytypes, function (item, index) {
    return _c('el-radio-button', {
      key: index,
      attrs: {
        "label": item.code
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "价格信息",
      "required": ""
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_vm._l(_vm.formData.priceItems, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "price-from-form"
    }, [_c('div', {
      staticClass: "price-item"
    }, [_c('el-select', {
      attrs: {
        "placeholder": ""
      },
      model: {
        value: item.priceType,
        callback: function callback($$v) {
          _vm.$set(item, "priceType", $$v);
        },
        expression: "item.priceType"
      }
    }, [_c('el-option', {
      attrs: {
        "value": 1,
        "label": "均价"
      }
    }), _c('el-option', {
      attrs: {
        "value": 2,
        "label": "总价"
      }
    })], 1), _c('el-form-item', {
      attrs: {
        "prop": "priceItems." + index + ".".concat(item.isInterval ? 'priceFrom' : 'priceDesc'),
        "rules": [item.isInterval ? _vm.rules.required('', ['blur']) : {}, item.isInterval ? _vm.rules.number : {}]
      }
    }, [!item.isInterval ? _c('el-input', {
      staticClass: "w130",
      attrs: {
        "maxlength": "5",
        "placeholder": "价格描述，选填"
      },
      model: {
        value: item.priceDesc,
        callback: function callback($$v) {
          _vm.$set(item, "priceDesc", $$v);
        },
        expression: "item.priceDesc"
      }
    }) : _c('el-input', {
      staticClass: "w130",
      attrs: {
        "maxlength": "6",
        "placeholder": item.priceType == 1 ? '最低均价' : '最低总价'
      },
      model: {
        value: item.priceFrom,
        callback: function callback($$v) {
          _vm.$set(item, "priceFrom", $$v);
        },
        expression: "item.priceFrom"
      }
    })], 1), _c('span', {
      style: {
        opacity: item.isInterval ? 1 : 0
      }
    }, [_vm._v("-")]), _c('el-form-item', {
      attrs: {
        "prop": "priceItems." + index + ".priceTo",
        "rules": [_vm.rules.required('', ['blur']), _vm.rules.number]
      }
    }, [_c('el-input', {
      staticClass: "w172",
      attrs: {
        "placeholder": item.isInterval ? item.priceType == 1 ? '最高均价' : '最高总价' : '请输入'
      },
      model: {
        value: item.priceTo,
        callback: function callback($$v) {
          _vm.$set(item, "priceTo", $$v);
        },
        expression: "item.priceTo"
      }
    }, [_c('span', {
      attrs: {
        "slot": "append"
      },
      slot: "append"
    }, [_vm._v(" " + _vm._s(item.priceType == 1 ? "元/平米" : item.isInterval ? "万元/套" : "万元/套起") + " ")])])], 1), _c('el-checkbox', {
      model: {
        value: item.isInterval,
        callback: function callback($$v) {
          _vm.$set(item, "isInterval", $$v);
        },
        expression: "item.isInterval"
      }
    }, [_vm._v("价格区间")]), index > 0 ? _c('i', {
      staticClass: "el-icon-remove",
      on: {
        "click": function click($event) {
          return _vm.removePirceItem(index);
        }
      }
    }) : _vm._e()], 1)]);
  }), _vm.formData.priceItems.length < 3 ? _c('el-button', {
    staticClass: "add-price-btn",
    on: {
      "click": _vm.addPirceItem
    }
  }, [_vm._v("添加更多")]) : _vm._e(), _c('p', {
    staticClass: "f-form-tips"
  }, [_vm._v(" 最多可添加3组价格信息，价格描述不超过5个汉字 ")])], 2)]), _c('el-form-item', {
    attrs: {
      "label": "价格变动日期",
      "prop": "priceChangeDate",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "value-format": "yyyy-MM-dd",
      "placeholder": "选择日期"
    },
    model: {
      value: _vm.formData.priceChangeDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "priceChangeDate", $$v);
      },
      expression: "formData.priceChangeDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "备注说明"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "maxlength": "100"
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.toggleDialog = false;
      }
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h3', [_vm._v("价格记录")]), _c('p', [_vm._v(" 根据不同物业类型，创建相应价格信息；创建和更新开盘信息时会自动产生一条价格记录；前台只显示最新一条价格信息，历史记录请勿删除； ")])]);

}]

export { render, staticRenderFns }