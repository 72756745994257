<template>
  <div class="edit-estate-info">
    <el-form :model="formData" label-width="120px" ref="form" class="f-form">
      <h2 class="form-title mt0">基本信息</h2>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="项目名称："
            prop="estateName"
            :rules="[rules.required()]"
          >
            <el-input
              placeholder="请输入"
              v-model="formData.estateName"
              maxlength="30"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目别名：" prop="estateAliasName">
            <el-input
              placeholder="支持多个项目别名，多个别名，用逗号分隔"
              v-model="formData.estateAliasName"
              maxlength="30"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="营销电话："
            prop="marketPhone"
            :rules="[rules.phone]"
          >
            <el-input
              placeholder="请输入座机、手机或400电话，只能输入一个"
              v-model="formData.marketPhone"
              maxlength="30"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="城市商圈："
            prop="companyCode"
            :rules="[rules.required()]"
          >
            <el-cascader
              class="w160 mr10 fl"
              v-model="formData.companyCode"
              :options="cityList"
              :props="{
                label: 'companyName',
                value: 'companyCode',
                emitPath: false
              }"
              placeholder="请选择城市"
              @change="cityChange"
            ></el-cascader>
            <el-form-item class="fl" prop="areaId" :rules="[rules.required()]">
              <el-cascader
                class="w160"
                v-model="formData.areaId"
                v-if="showAreaCascader"
                :options="areaList"
                :emitPath="false"
                separator=" - "
                :props="{
                  label: 'name',
                  value: 'id',
                  emitPath: false,
                  children: 'child'
                }"
                placeholder="请选择商圈"
              ></el-cascader>
            </el-form-item>
          </el-form-item>
          <el-form-item
            label="物业类型："
            prop="propertyTypesList"
            :rules="[rules.required()]"
          >
            <el-checkbox-group v-model="formData.propertyTypesList">
              <el-checkbox
                v-for="(value, key) in propertyTypesList"
                :label="Number(key)"
                :key="key"
                >{{ value }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="销售状态："
            prop="saleStatus"
            :rules="[rules.required()]"
          >
            <el-radio-group v-model="formData.saleStatus">
              <el-radio-button
                v-for="(value, key) in saleStatus"
                :label="key"
                :key="Number(key)"
                >{{ value }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="房源状态："
            prop="saleStatus"
            :rules="[rules.required()]"
          >
            <el-radio-group v-model="formData.estateHouseStatus">
              <el-radio-button
                v-for="(value, key) in estateHouseStatus"
                :label="key"
                :key="Number(key)"
                >{{ value }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="主推面积："
            prop="houseAreaFrom"
            :rules="[rules.required(), rules.int]"
          >
            <div class="line-box">
              <el-input
                class="w150 fl"
                placeholder="最小面积"
                maxlength="10"
                v-model.number="formData.houseAreaFrom"
              ></el-input>
              <span class="pw10 fl">-</span>
              <el-form-item
                class="fl"
                prop="houseAreaTo"
                :rules="[rules.required(), rules.int]"
              >
                <el-input
                  class="w200"
                  placeholder="最大面积"
                  maxlength="10"
                  v-model.number="formData.houseAreaTo"
                >
                  <span slot="append">平米</span>
                </el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="装修情况：">
            <el-input
              v-model="formData.decorationStandard"
              maxlength="30"
              placeholder="装修情况"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="location-wrapper">
            <ul class="tabs">
              <li
                :class="{ active: locationName == 'estate' }"
                @click="locationName = 'estate'"
              >
                <span>*</span>项目地址
              </li>
              <li
                :class="{ active: locationName == 'office' }"
                @click="locationName = 'office'"
              >
                售楼部地址
              </li>
            </ul>
            <el-form-item
              prop="estateAddress"
              label-width="0"
              :rules="[rules.required()]"
              v-show="locationName == 'estate'"
              class="mt20"
            >
              <div class="location-box">
                <el-input
                  v-model="formData.estateAddress"
                  placeholder="街道、门牌地址"
                ></el-input>
                <div class="btn-location">
                  <span>{{ formData.estateLL || "经纬度" }}</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              prop="officeAddress"
              label-width="0"
              v-show="locationName == 'office'"
              class="mt20"
            >
              <div class="location-box">
                <el-input
                  v-model="formData.officeAddress"
                  placeholder="街道、门牌地址"
                ></el-input>
                <div class="btn-location">
                  <span>{{ formData.officeLL || "经纬度" }}</span>
                </div>
              </div>
            </el-form-item>
            <p class="f-form-tips" style="margin-top:-10px;">
              请在地图上准确标注当前项目的位置坐标
            </p>
            <div class="map-wrap mt10">
              <a-map
                @getLL="getLL"
                :location="
                  locationName == 'estate'
                    ? [formData.estateLongitude, formData.estateLatitude]
                    : [formData.officeLongitude, formData.officeLatitude]
                "
              ></a-map>
            </div>
          </div>
        </el-col>
        <el-col>
          <el-form-item label="项目特点：">
            <div class="f-form-block">
              <el-checkbox-group v-model="formData.characteristicsSystemList">
                <el-checkbox
                  v-for="(item, index) in characteristicsSystemList"
                  :key="index"
                  :label="item"
                  style="width:120px;"
                  :disabled="
                    isDisableCharacteristic &&
                      !formData.characteristicsSystemList.includes(item)
                  "
                ></el-checkbox>
              </el-checkbox-group>
              <el-checkbox-group v-model="formData.characteristicsList">
                <el-checkbox
                  v-for="(item, index) in characteristicsList"
                  :key="index"
                  :label="item"
                  style="width:120px;"
                  :disabled="
                    isDisableCharacteristic &&
                      !formData.characteristicsList.includes(item)
                  "
                ></el-checkbox>
              </el-checkbox-group>
              <el-input
                v-model="diyTag"
                maxlength="5"
                class="w180"
                placeholder="自定义"
                size="small"
                :disabled="isDisableCharacteristic"
              >
                <el-button type="blue-text" slot="append" @click="addTag"
                  >确定</el-button
                >
              </el-input>
              <p class="f-form-tips mt10">
                最多添加4个标签，自定义标签不超过5个汉字
              </p>
            </div>
          </el-form-item>
          <el-form-item
            label="状态："
            prop="shelvesStatus"
            :rules="[rules.required()]"
          >
            <el-radio-group v-model="formData.shelvesStatus">
              <el-radio-button :label="1">上架</el-radio-button>
              <el-radio-button :label="2">下架</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formData.shelvesStatus == 1"
            label=""
            prop="onLineChannel"
            :rules="[rules.required('至少选择一个')]"
            class="choose-channel"
          >
            <div class="f-form-block">
              <div class="tip">上架平台 <span>（至少上架1个平台）</span></div>
              <el-checkbox-group v-model="formData.onLineChannel">
                <el-checkbox
                  :label="item.id"
                  v-for="(item, index) in channelList"
                  :key="index"
                  :disabled="item.disabled"
                  style="width:170px;"
                  >{{ item.letTitle }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item
            label="图片相关："
            prop="sitePlan"
            :rules="[rules.required()]"
          >
            <div class="img-uploder-wrap">
              <el-form-item prop="sitePlan" :rules="[rules.required()]">
                <f-upload
                  title="项目缩略图"
                  tips="前台列表缩略图，推荐尺寸300px * 400px"
                  required
                  :type="2"
                  :default-file-list="[{ url: formData.sitePlan }]"
                  @on-success="fileList => uploadSuccess(fileList, 'sitePlan')"
                ></f-upload>
              </el-form-item>
              <f-upload
                title="项目标识"
                tips="独立部署小程序时必填，推荐尺寸320px * 320px"
                :type="2"
                :default-file-list="[{ url: formData.estateLogo }]"
                @on-success="fileList => uploadSuccess(fileList, 'estateLogo')"
              ></f-upload>
              <f-upload
                title="分享模板图"
                tips="楼盘分享海报模板，推荐尺寸1080px * 1920px"
                :type="2"
                :default-file-list="[{ url: formData.estateCoverImage }]"
                @on-success="
                  fileList => uploadSuccess(fileList, 'estateCoverImage')
                "
              ></f-upload>
              <f-upload
                title="购房群推广图"
                tips="建议制作加入购房群的方法，大小1M以内为佳"
                :type="2"
                :default-file-list="[{ url: formData.wechatQrcodePath }]"
                @on-success="
                  fileList => uploadSuccess(fileList, 'wechatQrcodePath')
                "
              ></f-upload>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="浏览次数">
            <el-input
              class="w220"
              v-model.number="formData.viewCount"
              :rules="[rules.number]"
            ></el-input>
          </el-form-item>
          <el-form-item label="浏览人数">
            <el-input
              class="w220"
              v-model.number="formData.userCount"
              :rules="[rules.number]"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <h2 class="form-title">规划信息</h2>
      <el-row>
        <el-col :span="24">
          <el-form-item label="类型：">
            <el-checkbox-group v-model="formData.buildingTypeList">
              <el-checkbox
                v-for="(item, index) in buildingTypeList"
                :key="index"
                :label="item"
                :disabled="
                  formData.buildingTypeList.length >= 5 &&
                    !formData.buildingTypeList.includes(item)
                "
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="greenRate"
            label="绿化率："
            :rules="[rules.number]"
          >
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.greenRate"
            >
              <span slot="append" style="padding: 0 8px;">%</span>
            </el-input>
          </el-form-item>
          <el-form-item label="总占地面积：" :rules="[rules.int]">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model.number="formData.floorArea"
            >
              <span slot="append">平米</span>
            </el-input>
          </el-form-item>
          <el-form-item label="规划户数：" :rules="[rules.int]">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model.number="formData.totalResidentCount"
            ></el-input>
          </el-form-item>
          <el-form-item label="梯户比：">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.liftRate"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="外摆空间："
            v-if="formData.propertyTypesList.includes(5)"
          >
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.outerSpace"
            ></el-input>
          </el-form-item>
          <el-form-item label="开发商：">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.developerName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="volumeRate"
            label="容积率："
            :rules="[rules.number]"
          >
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.volumeRate"
            ></el-input>
          </el-form-item>
          <el-form-item label="总建筑面积：" :rules="[rules.int]">
            <el-input placeholder="请输入" v-model.number="formData.buildArea">
              <span slot="append">平米</span>
            </el-input>
          </el-form-item>
          <el-form-item label="楼层状况：">
            <el-input
              placeholder="请输入"
              maxlength="150"
              v-model="formData.floorCondition"
            ></el-input>
          </el-form-item>
          <el-form-item label="层高情况：">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.storey"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="烟道情况："
            v-if="formData.propertyTypesList.includes(5)"
          >
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.flue"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="适合业态:"
            v-if="formData.propertyTypesList.includes(5)"
          >
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.suitableBusiness"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="产权年限：">
            <el-checkbox-group v-model="formData.propertyRightLimitList">
              <el-checkbox
                v-for="(item, index) in propertyRightLimitList"
                :label="item"
                :key="index"
                >{{ item }}年</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <h2 class="form-title">配套信息</h2>
      <el-row>
        <el-col :span="12">
          <el-form-item label="物业公司：">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.propertyCompany"
            ></el-input>
          </el-form-item>
          <el-form-item label="物业费：">
            <el-input
              placeholder="请输入"
              v-model="formData.propertyPrice"
            ></el-input>
          </el-form-item>
          <el-form-item label="供水方式：">
            <el-select v-model="formData.waterSupply">
              <el-option
                v-for="(item, index) in waterSupply"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供气方式：">
            <el-select v-model="formData.gasSupply">
              <el-option
                v-for="(item, index) in gasSupply"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车位情况：">
            <el-input
              placeholder="请输入"
              maxlength="30"
              v-model="formData.parkingSpaceCount"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="parkingRate"
            :rules="[rules.number]"
            label="车位配比："
          >
            <el-input
              placeholder="请输入数字"
              maxlength="30"
              v-model="formData.parkingRate"
            >
              <p slot="prepend">
                <span class="ratio-num">1：</span>
              </p>
            </el-input>
          </el-form-item>
          <el-form-item label="供电方式：">
            <el-select v-model="formData.electricSupply">
              <el-option
                v-for="(item, index) in electricSupply"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供暖方式：">
            <el-select v-model="formData.heatingSupply">
              <el-option
                v-for="(item, index) in heatingSupply"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <h2 class="form-title">其他信息</h2>
      <el-row>
        <el-form-item prop="name" label="项目介绍：">
          <el-input
            class="estate-introduction"
            type="textarea"
            maxlength="1000"
            rows="10"
            v-model="formData.estateIntroduce"
            show-word-limit
            placeholder="请输入项目介绍，支持换行，最多不超过1000字"
          ></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <el-button type="primary" class="submit-btn" @click="submit"
      >保存</el-button
    >
  </div>
</template>

<script>
import rules from "@/utils/rules";
import aMap from "@/components/amap";
import { editEstateBase, getEstateDetail } from "@/api/estate";
import { getSiteList, getAreaListBySite } from "@/api/common";
import { enumeration } from "@/config";
import FUpload from "@/components/upload";
import editEstateMixin from "@/views/estate/_mixins/editEstateMixin";

const buildingTypes = {
  //建筑类型（类型）
  1: ["多层", "小高层", "高层", "超高层", "花园洋房"], //住宅
  2: ["LOFT", "酒店公寓", "普通公寓"], //公寓
  3: ["独栋别墅", "双拼别墅", "联排别墅", "叠拼别墅", "四合院"], //别墅
  4: ["标准写字楼", "商务园区", "商业独栋", "商办"], //写字楼
  5: ["社区底商", "临街铺面", "商业街", "购物中心", "专业市场", "商业综合体"] //商铺
};

const planningInfo = {
  //规划信息 ,
  liftRate: "", //梯户比
  outerSpace: "", //外摆空间
  storey: "", //层高情况
  flue: "", //烟道情况
  suitableBusiness: "", //适合业态
  buildArea: null, //总建筑面积 ,
  buildingTypeList: [], //建筑类型
  developerName: "", //开发商 ,
  floorArea: null, //总占地面积 ,
  floorCondition: "", //楼层状况 ,
  greenRate: null, //绿化率 ,
  propertyRightLimitList: [], //产权年限 ,
  totalResidentCount: null, //规划户数 ,
  volumeRate: null //容积率
};
const supportInfo = {
  //配套信息
  estateIntroduce: "", //项目信息
  parkingRate: null, // 车位配比 ,
  parkingSpaceCount: "", //停车位情况 ,
  propertyCompany: "", // 物业公司 ,
  propertyPrice: "", //物业费
  electricSupply: "", //供电方式
  gasSupply: "", //供气方式
  heatingSupply: "", //供暖方式
  waterSupply: "" //供水方式
};

const baseInfo = {
  //基本信息
  officeAddress: "", //售楼部地址
  companyCode: null, //城市站点id 前端用
  areaId: null, //所属商圈 ,
  characteristicsList: [], //楼盘特点 ,
  characteristicsSystemList: [], //楼盘特点(系统预设) ,
  decorationStandard: "", //装修标准 ,
  estateAddress: "", //项目详细地址 ,
  estateCoverImage: "", //首页分享模板图 ,
  estateLogo: "", //项目标识
  estateHouseStatus: 1, //房源状态
  estateLatitude: "", //项目地址纬度 ,
  estateLongitude: "", //项目地址经度 ,
  estateLL: "", //【前端用】经纬度
  officeLatitude: "", //售楼部地址纬度 ,
  officeLongitude: "", //售楼部经度 ,
  officeLL: "", //【前端用】售楼部经纬度
  estateName: "", //项目名
  estateAliasName: "", //项目别名
  houseAreaFrom: "", //面积段
  houseAreaTo: "",
  propertyTypesList: [], //物业类型:1--普通住宅，2--公寓，3--别墅，4--写字楼，5--商铺 ,
  saleStatus: 1, //销售状态:1.待售 2.即将开盘 3.在售 4.下期待开 5.已售罄 ,
  sitePlan: "", //项目缩略图 ,
  visibleRange: 1, // 可见范围:1.企业内部展示 2.面向渠道展示 3.面向全网展示 ,
  wechatQrcodePath: "", //购房群推广图
  userCount: null, // 浏览人数
  viewCount: null, // 浏览次数
  shelvesStatus: 1, // 上架状态
  onLineChannel: [], // 上架平台
  marketPhone: "" // 营销电话
};

const formData = {
  //表单数据
  ...baseInfo,
  ...planningInfo,
  ...supportInfo
};
export default {
  mixins: [editEstateMixin],
  components: {
    aMap,
    FUpload
  },
  data() {
    return {
      rules,
      list: [{}], //table数据
      toggleFormDialog: false,
      toggleStatusDialog: false,
      total: 0, //楼盘总数
      queryData: {
        //查询字段
        // enterpriseId: 0, //企业id
        keyword: "",
        pageNum: 1,
        pageSize: 10
      },
      visibleRange: {
        1: {
          label: "企业内部展示",
          icon: "network-lock"
        },
        2: {
          label: "面向渠道展示",
          icon: "tier"
        },
        3: {
          label: "面向全网展示",
          icon: "network"
        }
      },
      propertyRightLimitList: ["70", "50", "40"], //产权年限
      propertyTypesList: enumeration.propertyTypesList, //物业类型
      saleStatus: enumeration.saleStatus, //销售状态
      estateHouseStatus: enumeration.estateHouseStatus, //房源状态
      characteristicsSystemList: [], //楼盘特色标签
      characteristicsList: [], //自定义楼盘特色标签
      buildingTypeList: [], //建筑类型
      formData: JSON.parse(JSON.stringify(formData)),
      diyTag: "", //自定义标签输入框值
      statusForm: {
        shelvesStatus: "",
        visibleRange: ""
      },
      waterSupply: ["民水", "商水"], //供水方式
      electricSupply: ["民电", "商电"], //供电方式
      gasSupply: ["不通气", "民气", "商气"], //供气方式
      heatingSupply: ["自采暖", "集中采暖", "市政供暖"], //供暖方式
      metroList: [], //当前选择位置附近地铁站
      locationName: "estate"
    };
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  watch: {
    "formData.propertyTypesList": {
      deep: true,
      handler(arr) {
        this.mergeTypes(arr);
        this.getSystemCharacteris(); // 根据物业类型，筛选项目特点
      }
    },
    characteristicsSystemList(arr) {
      //项目特点数据变化时检测已选的标签是否包含；否则删掉已选的标签
      this.formData.characteristicsSystemList.forEach((item, index) => {
        if (!arr.includes(item)) {
          this.formData.characteristicsSystemList.splice(index, 1, false);
        }
      });
      this.formData.characteristicsSystemList = this.formData.characteristicsSystemList.filter(
        item => item
      );
    }
  },
  created() {
    this.getEstateDetail();
    this.getEnterpriseChannelStatus();
  },
  methods: {
    mergeTypes(propertyTypesList) {
      //根据物业类型合并展示不同类型
      this.buildingTypeList = [];
      let list = [];
      for (let item of propertyTypesList) {
        this.buildingTypeList = [
          ...this.buildingTypeList,
          ...buildingTypes[item]
        ];
      }
      for (let item of this.buildingTypeList) {
        if (this.formData.buildingTypeList.includes(item)) {
          list.push(item);
        }
      }
      this.formData.buildingTypeList = list;
    },
    async getEstateDetail() {
      //查询楼盘详情
      const res = await getEstateDetail({ estateId: this.estateInfo.estateId });
      if (!res) return;
      const { baseInfo, planningInfo, supportInfo, estateId } = res;
      let formData = {};
      await this.cityChange(baseInfo.companyCode);
      formData = {
        ...baseInfo,
        ...planningInfo,
        ...supportInfo,
        estateId
      };
      for (let key in formData) {
        let item = formData[key];
        if (item) {
          this.formData[key] = item;
        }
      }
      this.mergeTypes(baseInfo.propertyTypesList);
      this.formData.estateLL = baseInfo.estateLongitude
        ? `${baseInfo.estateLongitude}，${baseInfo.estateLatitude}`
        : "";
      this.formData.officeLL = baseInfo.officeLongitude
        ? `${baseInfo.officeLongitude}，${baseInfo.officeLatitude}`
        : "";
      this.characteristicsList = this.$deepClone(
        this.formData.characteristicsList
      );
      this.$store.commit("SET_ESTATE_INFO", formData);
    },
    async uploadSuccess(fileList, type) {
      //上传图片成功
      this.formData[type] = fileList[0].url;
    },
    /* getNearby({ metro }) {
      //获得当前选择位置周边数据
      this.metroList = metro;
    }, */
    getLL({ address, position, addressComponent }) {
      //地图组件回调
      let { province, city, district } = addressComponent;
      if (this.locationName == "estate") {
        // 项目地址，替换省市区
        this.formData.estateAddress = address.replace(
          province + city + district,
          ""
        );
      }
      if (this.locationName == "office") {
        // 售楼部地址，替换省市
        this.formData.officeAddress = address.replace(province + city, "");
      }
      //地图组件回调
      this.formData[`${this.locationName}LL`] = position.join("，");
      this.formData[`${this.locationName}Longitude`] = position[0];
      this.formData[`${this.locationName}Latitude`] = position[1];
    },
    generateParam(data) {
      //按照接口需要参数格式生成参数
      let param = this.$deepClone(data);
      let result = {};
      let keys = ["baseInfo", "planningInfo", "supportInfo"];
      let objs = [baseInfo, planningInfo, supportInfo];
      result.estateId = param.estateId;
      result.baseInfo = {};
      result.planningInfo = {};
      result.supportInfo = {};
      for (let k = 0; k < keys.length; k++) {
        for (let i in param) {
          for (let j in objs[k]) {
            if (i === j) {
              result[keys[k]][i] = param[i];
            }
          }
        }
      }
      return result;
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (valid) {
          let param = this.generateParam(this.formData);
          const { houseAreaFrom, houseAreaTo } = param.baseInfo;
          if (Number(houseAreaFrom) > Number(houseAreaTo)) {
            this.$showError("最小面积不能大于最大面积");
            return;
          }
          const res = await editEstateBase(param);
          if (res) {
            this.$store.commit("SET_ESTATE_INFO", this.formData);
            this.$showSuccess("提交成功");
          }
        } else {
          // 验证不通过时，焦点跳转至第一个校验不通过处
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].querySelector("input").focus();
          });
        }
      });
    }
  }
};
</script>
<style lang="scss">
.estate-introduction {
  .el-textarea__inner {
    padding: 15px 15px 30px 15px;
  }
  .el-input__count {
    bottom: 1px;
  }
}
.edit-estate-info {
  .el-select {
    width: 100%;
  }
}
</style>

<style scoped lang="scss">
.mt40 {
  margin-top: 40px;
}
.edit-estate-info {
  .form-title {
    font-size: 16px;
    line-height: 22px;
    margin: 40px 0;
    padding-left: 10px;
    border-left: 3px solid $blue;
  }
  .img-uploder-wrap {
    display: flex;
    .item {
      width: 175px;
      height: 190px;
      padding: 10px;
      margin-right: 20px;
      border: 1px solid #f2f2f2;
      .uploader {
        height: 130px;
        .text,
        img {
          width: 155px;
          height: 130px;
          color: $blue;
          background: #f2f2f2;
          i {
            font-size: 18px;
            margin-right: 5px;
          }
        }
      }
      .f-span-required,
      .label {
        line-height: 50px;
        text-align: center;
      }
    }
  }
  .map-wrap {
    width: 100%;
    height: 245px;
  }
  .submit-btn {
    display: block;
    width: 180px;
    margin: 50px auto 0;
  }
  .ratio-num {
    font-weight: bold;
    font-size: 16px;
  }
}
.nearby-metro {
  margin-top: 15px;
  max-height: 165px;
  overflow-y: auto;
  .item + .item {
    margin-top: 10px;
  }
}
.location-wrapper {
  margin-left: 55px;
  .tabs {
    display: flex;
    li {
      flex: 1;
      line-height: 34px;
      color: #666;
      border: 1px solid #dcdfe6;
      text-align: center;
      background-color: #f2f2f2;
      cursor: pointer;
      span {
        color: #f5222d;
        margin-right: 5px;
      }
      &:hover {
        opacity: 0.75;
      }
      &.active {
        border-bottom-color: transparent;
        background-color: #fff;
      }
    }
    li + li {
      border-left: none;
    }
  }
}
.location-box {
  display: flex;
  align-items: center;
  ::v-deep {
    .el-input {
      flex: 1;
    }
  }
  .btn-location {
    width: 180px;
    line-height: 34px;
    border: 1px solid #dcdfe6;
    background-color: #f2f2f2;
    padding: 0 10px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 20px;
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
