<template>
  <div class="estate-price-info">
    <div class="head x3">
      <div class="title">
        <h3>价格记录</h3>
        <p>
          根据不同物业类型，创建相应价格信息；创建和更新开盘信息时会自动产生一条价格记录；前台只显示最新一条价格信息，历史记录请勿删除；
        </p>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="toggleDialog = true"
          >创建价格</el-button
        >
      </div>
    </div>
    <el-table :data="list" class="mt20">
      <el-table-column label="价格信息" width="500px">
        <template slot-scope="scope">
          <p
            v-for="(item, index) in handleListPriceInfo(scope.row.priceItems)"
            :key="index"
          >
            {{ item.label }}：{{ item.desc }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="物业类型"
        prop="propertyTypeName"
      ></el-table-column>
      <el-table-column
        label="价格变动日期"
        prop="priceChangeDate"
      ></el-table-column>
      <el-table-column label="备注" prop="remark">
        <template slot-scope="scope">
          <p>{{ scope.row.remark || "无" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px">
        <template slot-scope="scope">
          <el-button type="text" @click="showEdit(scope.row)">编辑</el-button>
          <el-button type="text" class="red" @click="delRecord(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="toggleDialog"
      class="f-dialog"
      :title="`${formData.id ? '编辑' : '创建'}价格信息`"
      width="650px"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        :model="formData"
        ref="form"
        label-position="top"
        class="f-form required-type2"
      >
        <el-form-item
          label="物业类型"
          prop="propertyType"
          :rules="[rules.required()]"
        >
          <el-radio-group v-model="formData.propertyType">
            <el-radio-button
              v-for="(item, index) in propertytypes"
              :key="index"
              :label="item.code"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="价格信息" required>
          <div class="f-form-block">
            <div
              v-for="(item, index) in formData.priceItems"
              :key="index"
              class="price-from-form"
            >
              <div class="price-item">
                <el-select placeholder="" v-model="item.priceType">
                  <el-option :value="1" label="均价"></el-option>
                  <el-option :value="2" label="总价"></el-option>
                </el-select>
                <el-form-item
                  :prop="
                    `priceItems.` +
                      index +
                      `.${item.isInterval ? 'priceFrom' : 'priceDesc'}`
                  "
                  :rules="[
                    item.isInterval ? rules.required('', ['blur']) : {},
                    item.isInterval ? rules.number : {}
                  ]"
                >
                  <el-input
                    v-if="!item.isInterval"
                    class="w130"
                    maxlength="5"
                    placeholder="价格描述，选填"
                    v-model="item.priceDesc"
                  ></el-input>
                  <el-input
                    v-else
                    class="w130"
                    maxlength="6"
                    :placeholder="item.priceType == 1 ? '最低均价' : '最低总价'"
                    v-model="item.priceFrom"
                  ></el-input>
                </el-form-item>
                <span :style="{ opacity: item.isInterval ? 1 : 0 }">-</span>
                <el-form-item
                  :prop="`priceItems.` + index + `.priceTo`"
                  :rules="[rules.required('', ['blur']), rules.number]"
                >
                  <el-input
                    class="w172"
                    :placeholder="
                      item.isInterval
                        ? item.priceType == 1
                          ? '最高均价'
                          : '最高总价'
                        : '请输入'
                    "
                    v-model="item.priceTo"
                  >
                    <span slot="append">
                      {{
                        item.priceType == 1
                          ? "元/平米"
                          : item.isInterval
                          ? "万元/套"
                          : "万元/套起"
                      }}
                    </span>
                  </el-input>
                </el-form-item>
                <el-checkbox v-model="item.isInterval">价格区间</el-checkbox>
                <i
                  class="el-icon-remove"
                  v-if="index > 0"
                  @click="removePirceItem(index)"
                ></i>
              </div>
            </div>
            <el-button
              class="add-price-btn"
              v-if="formData.priceItems.length < 3"
              @click="addPirceItem"
              >添加更多</el-button
            >
            <p class="f-form-tips">
              最多可添加3组价格信息，价格描述不超过5个汉字
            </p>
          </div>
        </el-form-item>
        <el-form-item
          label="价格变动日期"
          prop="priceChangeDate"
          :rules="[rules.required()]"
        >
          <el-date-picker
            v-model="formData.priceChangeDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注说明">
          <el-input
            type="textarea"
            maxlength="100"
            v-model="formData.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="toggleDialog = false">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPriceList,
  getEstatePropertytypes,
  createPrice,
  editPrice,
  deletePrice
} from "@/api/estate";
import rules from "@/utils/rules";
import mixin from "../mixin";

const priceItem = {
  isInterval: false, //0.非区间 1.区间
  priceDesc: "", //价格描述
  priceFrom: "",
  priceId: null,
  priceTo: "",
  priceType: 1 //价格类型：1:单价(单位元) 2:总价(单位万元)
};

const formData = {
  id: null,
  priceChangeDate: new Date(), //价格变动日期 ,
  priceItems: [JSON.parse(JSON.stringify(priceItem))], //价格项 ,
  propertyType: "", //1--普通住宅，2--公寓，3--别墅，4--写字楼，5--商铺 ,
  remark: "" //备注说明
};
export default {
  mixins: [mixin],
  data() {
    return {
      rules,
      toggleDialog: false, //编辑对话框
      list: [{}],
      formData: JSON.parse(JSON.stringify(formData)),
      propertytypes: []
    };
  },
  created() {
    this.getPriceList();
    this.getEstatePropertytypes();
  },
  methods: {
    delRecord(id) {
      //删除记录
      this.$confirm("删除后无法恢复，确定删除该记录吗？", "删除记录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deletePrice({ id });
          if (res) {
            this.$showSuccess("删除成功");
            this.getPriceList();
          }
        })
        .catch(() => {});
    },
    showEdit(item) {
      //点击编辑
      this.formData = this.$deepClone(item);
      this.toggleDialog = true;
    },
    resetForm() {
      //重置表单
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields();
    },
    async getPriceList() {
      // 获取价格列表
      const res = await getPriceList();
      if (res) {
        this.list = res;
      }
    },
    async getEstatePropertytypes() {
      // 查询楼盘物业类型
      const res = await getEstatePropertytypes();
      if (res) {
        this.propertytypes = res;
      }
    },
    addPirceItem() {
      //添加价格条目
      if (this.formData.priceItems.length < 3) {
        this.formData.priceItems.push(this.$deepClone(priceItem));
      }
    },
    removePirceItem(index) {
      //移除价格条目
      this.formData.priceItems.splice(index, 1);
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        let param = this.$deepClone(this.formData);
        let limitFormatError = false;
        param.priceItems = param.priceItems.map(item => {
          if (!item.isInterval) {
            //如果选的不是价格区间，为priceFrom赋值
            item.priceFrom = item.priceTo;
          } else {
            item.priceDesc = "";
            if (Number(item.priceFrom) > Number(item.priceTo)) {
              limitFormatError = true;
            }
          }
          return item;
        });
        if (limitFormatError) {
          this.$showError("最小金额不能大于最大金额");
          return;
        }
        const fn = this.formData.id ? editPrice : createPrice;
        const res = await fn(param);
        if (res) {
          this.$showSuccess("操作成功");
          this.resetForm();
          this.getPriceList();
          this.toggleDialog = false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.f-form {
  padding: 0 20px;
}
.estate-price-info {
  .el-form {
    .price-item {
      display: flex;
      align-items: center;
      & > * {
        margin-left: 10px;
      }
      .el-input-group__append {
        width: 70px;
        padding: 0;
        text-align: center;
      }
      .el-select {
        min-width: 74px;
        max-width: 74px;
        margin-left: 10px;
      }
      .w130 {
        width: 130px;
      }
      .w172 {
        width: 172px;
      }
      .el-checkbox {
        .el-checkbox__inner {
          margin-left: 5px;
          transform: scale(1.5);
        }
      }
      .el-icon-remove {
        font-size: 24px;
        color: $red;
        cursor: pointer;
        &:active {
          opacity: 0.75;
        }
      }
    }
    .price-from-form {
      margin-bottom: 10px;
    }
    .add-price-btn {
      padding: 9.5px 20px;
      margin: 0 0 10px 10px;
      width: 100px;
      color: $blue;
      background: #eee;
    }
    .f-form-tips {
      margin-left: 10px;
    }
  }
}
</style>

<style scoped lang="scss">
.head {
  .title {
    h3 {
      font-size: 16px;
      line-break: 16px;
      padding-left: 20px;
      border-left: 3px solid $primary;
    }
    p {
      margin-top: 10px;
      padding-left: 20px;
      color: $color9;
    }
  }
}
</style>
