var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-estate-info"
  }, [_c('el-form', {
    ref: "form",
    staticClass: "f-form",
    attrs: {
      "model": _vm.formData,
      "label-width": "120px"
    }
  }, [_c('h2', {
    staticClass: "form-title mt0"
  }, [_vm._v("基本信息")]), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "项目名称：",
      "prop": "estateName",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.estateName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateName", $$v);
      },
      expression: "formData.estateName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "项目别名：",
      "prop": "estateAliasName"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "支持多个项目别名，多个别名，用逗号分隔",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.estateAliasName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateAliasName", $$v);
      },
      expression: "formData.estateAliasName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "营销电话：",
      "prop": "marketPhone",
      "rules": [_vm.rules.phone]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入座机、手机或400电话，只能输入一个",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.marketPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "marketPhone", $$v);
      },
      expression: "formData.marketPhone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "城市商圈：",
      "prop": "companyCode",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-cascader', {
    staticClass: "w160 mr10 fl",
    attrs: {
      "options": _vm.cityList,
      "props": {
        label: 'companyName',
        value: 'companyCode',
        emitPath: false
      },
      "placeholder": "请选择城市"
    },
    on: {
      "change": _vm.cityChange
    },
    model: {
      value: _vm.formData.companyCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "companyCode", $$v);
      },
      expression: "formData.companyCode"
    }
  }), _c('el-form-item', {
    staticClass: "fl",
    attrs: {
      "prop": "areaId",
      "rules": [_vm.rules.required()]
    }
  }, [_vm.showAreaCascader ? _c('el-cascader', {
    staticClass: "w160",
    attrs: {
      "options": _vm.areaList,
      "emitPath": false,
      "separator": " - ",
      "props": {
        label: 'name',
        value: 'id',
        emitPath: false,
        children: 'child'
      },
      "placeholder": "请选择商圈"
    },
    model: {
      value: _vm.formData.areaId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "areaId", $$v);
      },
      expression: "formData.areaId"
    }
  }) : _vm._e()], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "物业类型：",
      "prop": "propertyTypesList",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.propertyTypesList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyTypesList", $$v);
      },
      expression: "formData.propertyTypesList"
    }
  }, _vm._l(_vm.propertyTypesList, function (value, key) {
    return _c('el-checkbox', {
      key: key,
      attrs: {
        "label": Number(key)
      }
    }, [_vm._v(_vm._s(value))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "销售状态：",
      "prop": "saleStatus",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.saleStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "saleStatus", $$v);
      },
      expression: "formData.saleStatus"
    }
  }, _vm._l(_vm.saleStatus, function (value, key) {
    return _c('el-radio-button', {
      key: Number(key),
      attrs: {
        "label": key
      }
    }, [_vm._v(_vm._s(value))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "房源状态：",
      "prop": "saleStatus",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.estateHouseStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateHouseStatus", $$v);
      },
      expression: "formData.estateHouseStatus"
    }
  }, _vm._l(_vm.estateHouseStatus, function (value, key) {
    return _c('el-radio-button', {
      key: Number(key),
      attrs: {
        "label": key
      }
    }, [_vm._v(_vm._s(value))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "主推面积：",
      "prop": "houseAreaFrom",
      "rules": [_vm.rules.required(), _vm.rules.int]
    }
  }, [_c('div', {
    staticClass: "line-box"
  }, [_c('el-input', {
    staticClass: "w150 fl",
    attrs: {
      "placeholder": "最小面积",
      "maxlength": "10"
    },
    model: {
      value: _vm.formData.houseAreaFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseAreaFrom", _vm._n($$v));
      },
      expression: "formData.houseAreaFrom"
    }
  }), _c('span', {
    staticClass: "pw10 fl"
  }, [_vm._v("-")]), _c('el-form-item', {
    staticClass: "fl",
    attrs: {
      "prop": "houseAreaTo",
      "rules": [_vm.rules.required(), _vm.rules.int]
    }
  }, [_c('el-input', {
    staticClass: "w200",
    attrs: {
      "placeholder": "最大面积",
      "maxlength": "10"
    },
    model: {
      value: _vm.formData.houseAreaTo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseAreaTo", _vm._n($$v));
      },
      expression: "formData.houseAreaTo"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("平米")])])], 1)], 1)]), _c('el-form-item', {
    attrs: {
      "label": "装修情况："
    }
  }, [_c('el-input', {
    attrs: {
      "maxlength": "30",
      "placeholder": "装修情况"
    },
    model: {
      value: _vm.formData.decorationStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "decorationStandard", $$v);
      },
      expression: "formData.decorationStandard"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "location-wrapper"
  }, [_c('ul', {
    staticClass: "tabs"
  }, [_c('li', {
    class: {
      active: _vm.locationName == 'estate'
    },
    on: {
      "click": function click($event) {
        _vm.locationName = 'estate';
      }
    }
  }, [_c('span', [_vm._v("*")]), _vm._v("项目地址 ")]), _c('li', {
    class: {
      active: _vm.locationName == 'office'
    },
    on: {
      "click": function click($event) {
        _vm.locationName = 'office';
      }
    }
  }, [_vm._v(" 售楼部地址 ")])]), _c('el-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locationName == 'estate',
      expression: "locationName == 'estate'"
    }],
    staticClass: "mt20",
    attrs: {
      "prop": "estateAddress",
      "label-width": "0",
      "rules": [_vm.rules.required()]
    }
  }, [_c('div', {
    staticClass: "location-box"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "街道、门牌地址"
    },
    model: {
      value: _vm.formData.estateAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateAddress", $$v);
      },
      expression: "formData.estateAddress"
    }
  }), _c('div', {
    staticClass: "btn-location"
  }, [_c('span', [_vm._v(_vm._s(_vm.formData.estateLL || "经纬度"))])])], 1)]), _c('el-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.locationName == 'office',
      expression: "locationName == 'office'"
    }],
    staticClass: "mt20",
    attrs: {
      "prop": "officeAddress",
      "label-width": "0"
    }
  }, [_c('div', {
    staticClass: "location-box"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "街道、门牌地址"
    },
    model: {
      value: _vm.formData.officeAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "officeAddress", $$v);
      },
      expression: "formData.officeAddress"
    }
  }), _c('div', {
    staticClass: "btn-location"
  }, [_c('span', [_vm._v(_vm._s(_vm.formData.officeLL || "经纬度"))])])], 1)]), _c('p', {
    staticClass: "f-form-tips",
    staticStyle: {
      "margin-top": "-10px"
    }
  }, [_vm._v(" 请在地图上准确标注当前项目的位置坐标 ")]), _c('div', {
    staticClass: "map-wrap mt10"
  }, [_c('a-map', {
    attrs: {
      "location": _vm.locationName == 'estate' ? [_vm.formData.estateLongitude, _vm.formData.estateLatitude] : [_vm.formData.officeLongitude, _vm.formData.officeLatitude]
    },
    on: {
      "getLL": _vm.getLL
    }
  })], 1)], 1)]), _c('el-col', [_c('el-form-item', {
    attrs: {
      "label": "项目特点："
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.characteristicsSystemList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "characteristicsSystemList", $$v);
      },
      expression: "formData.characteristicsSystemList"
    }
  }, _vm._l(_vm.characteristicsSystemList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      staticStyle: {
        "width": "120px"
      },
      attrs: {
        "label": item,
        "disabled": _vm.isDisableCharacteristic && !_vm.formData.characteristicsSystemList.includes(item)
      }
    });
  }), 1), _c('el-checkbox-group', {
    model: {
      value: _vm.formData.characteristicsList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "characteristicsList", $$v);
      },
      expression: "formData.characteristicsList"
    }
  }, _vm._l(_vm.characteristicsList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      staticStyle: {
        "width": "120px"
      },
      attrs: {
        "label": item,
        "disabled": _vm.isDisableCharacteristic && !_vm.formData.characteristicsList.includes(item)
      }
    });
  }), 1), _c('el-input', {
    staticClass: "w180",
    attrs: {
      "maxlength": "5",
      "placeholder": "自定义",
      "size": "small",
      "disabled": _vm.isDisableCharacteristic
    },
    model: {
      value: _vm.diyTag,
      callback: function callback($$v) {
        _vm.diyTag = $$v;
      },
      expression: "diyTag"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append",
      "type": "blue-text"
    },
    on: {
      "click": _vm.addTag
    },
    slot: "append"
  }, [_vm._v("确定")])], 1), _c('p', {
    staticClass: "f-form-tips mt10"
  }, [_vm._v(" 最多添加4个标签，自定义标签不超过5个汉字 ")])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "状态：",
      "prop": "shelvesStatus",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.shelvesStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "shelvesStatus", $$v);
      },
      expression: "formData.shelvesStatus"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("上架")]), _c('el-radio-button', {
    attrs: {
      "label": 2
    }
  }, [_vm._v("下架")])], 1)], 1), _vm.formData.shelvesStatus == 1 ? _c('el-form-item', {
    staticClass: "choose-channel",
    attrs: {
      "label": "",
      "prop": "onLineChannel",
      "rules": [_vm.rules.required('至少选择一个')]
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_c('div', {
    staticClass: "tip"
  }, [_vm._v("上架平台 "), _c('span', [_vm._v("（至少上架1个平台）")])]), _c('el-checkbox-group', {
    model: {
      value: _vm.formData.onLineChannel,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "onLineChannel", $$v);
      },
      expression: "formData.onLineChannel"
    }
  }, _vm._l(_vm.channelList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      staticStyle: {
        "width": "170px"
      },
      attrs: {
        "label": item.id,
        "disabled": item.disabled
      }
    }, [_vm._v(_vm._s(item.letTitle))]);
  }), 1)], 1)]) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "图片相关：",
      "prop": "sitePlan",
      "rules": [_vm.rules.required()]
    }
  }, [_c('div', {
    staticClass: "img-uploder-wrap"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "sitePlan",
      "rules": [_vm.rules.required()]
    }
  }, [_c('f-upload', {
    attrs: {
      "title": "项目缩略图",
      "tips": "前台列表缩略图，推荐尺寸300px * 400px",
      "required": "",
      "type": 2,
      "default-file-list": [{
        url: _vm.formData.sitePlan
      }]
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploadSuccess(fileList, 'sitePlan');
      }
    }
  })], 1), _c('f-upload', {
    attrs: {
      "title": "项目标识",
      "tips": "独立部署小程序时必填，推荐尺寸320px * 320px",
      "type": 2,
      "default-file-list": [{
        url: _vm.formData.estateLogo
      }]
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploadSuccess(fileList, 'estateLogo');
      }
    }
  }), _c('f-upload', {
    attrs: {
      "title": "分享模板图",
      "tips": "楼盘分享海报模板，推荐尺寸1080px * 1920px",
      "type": 2,
      "default-file-list": [{
        url: _vm.formData.estateCoverImage
      }]
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploadSuccess(fileList, 'estateCoverImage');
      }
    }
  }), _c('f-upload', {
    attrs: {
      "title": "购房群推广图",
      "tips": "建议制作加入购房群的方法，大小1M以内为佳",
      "type": 2,
      "default-file-list": [{
        url: _vm.formData.wechatQrcodePath
      }]
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploadSuccess(fileList, 'wechatQrcodePath');
      }
    }
  })], 1)])], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "浏览次数"
    }
  }, [_c('el-input', {
    staticClass: "w220",
    attrs: {
      "rules": [_vm.rules.number]
    },
    model: {
      value: _vm.formData.viewCount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "viewCount", _vm._n($$v));
      },
      expression: "formData.viewCount"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "浏览人数"
    }
  }, [_c('el-input', {
    staticClass: "w220",
    attrs: {
      "rules": [_vm.rules.number]
    },
    model: {
      value: _vm.formData.userCount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "userCount", _vm._n($$v));
      },
      expression: "formData.userCount"
    }
  })], 1)], 1)], 1), _c('h2', {
    staticClass: "form-title"
  }, [_vm._v("规划信息")]), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "类型："
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.buildingTypeList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "buildingTypeList", $$v);
      },
      expression: "formData.buildingTypeList"
    }
  }, _vm._l(_vm.buildingTypeList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      attrs: {
        "label": item,
        "disabled": _vm.formData.buildingTypeList.length >= 5 && !_vm.formData.buildingTypeList.includes(item)
      }
    });
  }), 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "greenRate",
      "label": "绿化率：",
      "rules": [_vm.rules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.greenRate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "greenRate", $$v);
      },
      expression: "formData.greenRate"
    }
  }, [_c('span', {
    staticStyle: {
      "padding": "0 8px"
    },
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("%")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "总占地面积：",
      "rules": [_vm.rules.int]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.floorArea,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "floorArea", _vm._n($$v));
      },
      expression: "formData.floorArea"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("平米")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "规划户数：",
      "rules": [_vm.rules.int]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.totalResidentCount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "totalResidentCount", _vm._n($$v));
      },
      expression: "formData.totalResidentCount"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "梯户比："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.liftRate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "liftRate", $$v);
      },
      expression: "formData.liftRate"
    }
  })], 1), _vm.formData.propertyTypesList.includes(5) ? _c('el-form-item', {
    attrs: {
      "label": "外摆空间："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.outerSpace,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "outerSpace", $$v);
      },
      expression: "formData.outerSpace"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "开发商："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.developerName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "developerName", $$v);
      },
      expression: "formData.developerName"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "volumeRate",
      "label": "容积率：",
      "rules": [_vm.rules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.volumeRate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "volumeRate", $$v);
      },
      expression: "formData.volumeRate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "总建筑面积：",
      "rules": [_vm.rules.int]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.buildArea,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "buildArea", _vm._n($$v));
      },
      expression: "formData.buildArea"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("平米")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "楼层状况："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "150"
    },
    model: {
      value: _vm.formData.floorCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "floorCondition", $$v);
      },
      expression: "formData.floorCondition"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "层高情况："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.storey,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "storey", $$v);
      },
      expression: "formData.storey"
    }
  })], 1), _vm.formData.propertyTypesList.includes(5) ? _c('el-form-item', {
    attrs: {
      "label": "烟道情况："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.flue,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "flue", $$v);
      },
      expression: "formData.flue"
    }
  })], 1) : _vm._e(), _vm.formData.propertyTypesList.includes(5) ? _c('el-form-item', {
    attrs: {
      "label": "适合业态:"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.suitableBusiness,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "suitableBusiness", $$v);
      },
      expression: "formData.suitableBusiness"
    }
  })], 1) : _vm._e()], 1), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "产权年限："
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.propertyRightLimitList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyRightLimitList", $$v);
      },
      expression: "formData.propertyRightLimitList"
    }
  }, _vm._l(_vm.propertyRightLimitList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      attrs: {
        "label": item
      }
    }, [_vm._v(_vm._s(item) + "年")]);
  }), 1)], 1)], 1)], 1), _c('h2', {
    staticClass: "form-title"
  }, [_vm._v("配套信息")]), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "物业公司："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.propertyCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyCompany", $$v);
      },
      expression: "formData.propertyCompany"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "物业费："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.propertyPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyPrice", $$v);
      },
      expression: "formData.propertyPrice"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "供水方式："
    }
  }, [_c('el-select', {
    model: {
      value: _vm.formData.waterSupply,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "waterSupply", $$v);
      },
      expression: "formData.waterSupply"
    }
  }, _vm._l(_vm.waterSupply, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "供气方式："
    }
  }, [_c('el-select', {
    model: {
      value: _vm.formData.gasSupply,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "gasSupply", $$v);
      },
      expression: "formData.gasSupply"
    }
  }, _vm._l(_vm.gasSupply, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "车位情况："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.parkingSpaceCount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "parkingSpaceCount", $$v);
      },
      expression: "formData.parkingSpaceCount"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "parkingRate",
      "rules": [_vm.rules.number],
      "label": "车位配比："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入数字",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.parkingRate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "parkingRate", $$v);
      },
      expression: "formData.parkingRate"
    }
  }, [_c('p', {
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend"
  }, [_c('span', {
    staticClass: "ratio-num"
  }, [_vm._v("1：")])])])], 1), _c('el-form-item', {
    attrs: {
      "label": "供电方式："
    }
  }, [_c('el-select', {
    model: {
      value: _vm.formData.electricSupply,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "electricSupply", $$v);
      },
      expression: "formData.electricSupply"
    }
  }, _vm._l(_vm.electricSupply, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "供暖方式："
    }
  }, [_c('el-select', {
    model: {
      value: _vm.formData.heatingSupply,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "heatingSupply", $$v);
      },
      expression: "formData.heatingSupply"
    }
  }, _vm._l(_vm.heatingSupply, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1)], 1)], 1), _c('h2', {
    staticClass: "form-title"
  }, [_vm._v("其他信息")]), _c('el-row', [_c('el-form-item', {
    attrs: {
      "prop": "name",
      "label": "项目介绍："
    }
  }, [_c('el-input', {
    staticClass: "estate-introduction",
    attrs: {
      "type": "textarea",
      "maxlength": "1000",
      "rows": "10",
      "show-word-limit": "",
      "placeholder": "请输入项目介绍，支持换行，最多不超过1000字"
    },
    model: {
      value: _vm.formData.estateIntroduce,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateIntroduce", $$v);
      },
      expression: "formData.estateIntroduce"
    }
  })], 1)], 1)], 1), _c('el-button', {
    staticClass: "submit-btn",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }